@use "@angular/material" as mat;
@include mat.core;

//  ==========================================
//  General settings
//  ==========================================
//  Paths
$assets-path: "/assets" !default;
$fonts-path: "#{$assets-path}/fonts/" !default;

//  ==========================================
//  Colors
//  ==========================================
//  General
$white: #ffffff !default;
$off-white: #fafafa !default;
$light-grey: #eaeaea !default;
$grey: #d9d9d9 !default;
$dark-grey: #2d2d2d !default;
$black: #000000 !default;

//  Procure Colors
$procure-brand-accent: #eb0023 !default;
$procure-brand-info: #093fb4 !default;
$procure-brand-success: #2f8c16 !default;
$procure-brand-warning: #ffb500 !default;
$procure-brand-error: #e86a70 !default;

$procure-status-progress: #4782ff !default;
$procure-status-done: #00b102 !default;

$procure-grey: #737373 !default;

//  Applied Layout Colors
$procure-text-color-page: #000000 !default;
$procure-bg-color-page: #f7f8f8 !default;
$procure-text-color-base: $procure-grey !default;
$procure-bg-color-base: #f7f8f8 !default;
$procure-bg-color-hover: #eef0f2 !default;
$procure-text-color-selected: #ffffff !default;

//  Applied Form Colors
$procure-label-color: $procure-grey !default;
$procure-select-color-selected: $procure-brand-accent !default;
$procure-select-border-color: $procure-grey !default;

//  ==========================================
//  Fonts
//  ==========================================
$font-family-sans-serif: "Arial", sans-serif !default;
$font-family-serif: "Georgia", "Times New Roman", "Times", serif !default;
$font-family-monospace: "Menlo", "Monaco", "Consolas", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$primary-font: $font-family-sans-serif !default;

$icon-default-color: rgb(0 0 0 / 54%) !default;
$procure-label-font-weight: 400 !default;

//  Buttons
//  Flat and default button standards
$procure-button-padding: 0.5em 1.5em !default;
$procure-button-min-width: 130px !default;
$procure-button-margin: 0 !default;
$procure-button-font-size: 0.875rem !default;
$procure-button-font-weight: 400 !default;
$procure-button-line-height: 1.25 !default;
$procure-button-border-radius: 999px !default;

//  Stroked Button standards
$procure-stroked-button-line-height: $procure-button-line-height !default;

//  Stroked button padding is 1px less horizontally than default/flat/raised
//  button's padding.
$procure-stroked-button-padding: calc(0.5em - 1px) calc(1.5em - 1px) !default;

$procure-button-disabled-color: $procure-text-color-page !default;
$procure-button-primary-color: $procure-brand-accent !default;
$procure-button-stroked-default-color: $procure-grey !default;
$procure-button-stroked-hover-color: #9b9b9b !default;
$procure-button-flat-default-color: $procure-grey !default;
$procure-button-flat-text-color: $white !default;
$procure-button-sharp-edge-default-color: #5b6f80 !default;
$procure-button-sharp-edge-text-color: #efefef !default;

//  ==========================================
//  Material
//  ==========================================

//  Procure Material Palette
//  http:// mcg.mbitson.com/#!?mcgpalette0=%23ff000f
$procure-brand-accent-palette: (
  50: #fde0e5,
  100: #f9b3bd,
  200: #f58091,
  300: #f14d65,
  400: #ee2644,
  500: #eb0023,
  600: #e9001f,
  700: #e5001a,
  800: #e20015,
  900: #dd000c,
  A100: #ffffff,
  A200: #ffd1d2,
  A400: #ff9ea0,
  A700: #ff8587,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
) !default;

$procure-brand-grey-palette: (
  50 : #eeeeee,
  100 : #d5d5d5,
  200 : #b9b9b9,
  300 : #9d9d9d,
  400 : #888888,
  500 : #737373,
  600 : #6b6b6b,
  700 : #606060,
  800 : #565656,
  900 : #434343,
  A100 : #f6acac,
  A200 : #f17e7e,
  A400 : #ff3d3d,
  A700 : #ff2424,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
) !default;

$procure-brand-error-palette: (
  50 : #fcedee,
  100 : #f8d2d4,
  200 : #f4b5b8,
  300 : #ef979b,
  400 : #eb8085,
  500 : $procure-brand-error,
  600 : #e56268,
  700 : #e2575d,
  800 : #de4d53,
  900 : #d83c41,
  A100 : #ffffff,
  A200 : #fff2f3,
  A400 : #ffbfc1,
  A700 : #ffa6a8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
) !default;

//  Define the palettes for your theme using the Material Design palettes available in palette.scss
//  (imported above). For each palette, you can optionally specify a default, lighter, and darker
//  hue.
$primary: mat.define-palette($procure-brand-grey-palette) !default;
$accent: mat.define-palette($procure-brand-accent-palette) !default;

//  The warn palette is optional (defaults to red).
$warn: mat.define-palette($procure-brand-error-palette) !default;

//  Define a custom typography config that overrides the font-family as well as the
//  `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: $primary-font,
  $body-1: mat.define-typography-level($font-size: inherit, $letter-spacing: normal),
) !default;

//  Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $custom-typography
  )
) !default;

//  ==========================================
//  Layout settings
//  ==========================================

// Spaces
$base-space: 24px !default;
$half-space: $base-space * 0.5;
$big-space: $base-space * 2;

// Index
$z-index-split-area: 900 !default;
$z-index-navigation-fixed: 950 !default;
$z-index-quick-search-pannel: 1000 !default;
$z-index-navigation-popover: 1030 !default;
$z-index-navigation-backdrop: 1029 !default;

$z-index-file-hover: 1000 !default;
$z-index-navbar: 1000 !default;
$z-index-overlay: 1100 !default;

$border-radius: 3px !default;
$box-shadow: 0 1px 3px rgba(#000000, 0.1) !default;

//  Breakpoints
$screen-sm-min: 48rem !default;
$screen-md-min: 62rem !default;
$screen-lg-min: 75rem !default;
$screen-xl-min: 87rem !default;

$breakpoints: (
  s: $screen-sm-min,
  m: $screen-md-min,
  l: $screen-lg-min,
  xl: $screen-xl-min,
) !default;
