@import "../base/variables";

.app-main {
  position: relative;
  flex-grow: 1;
  width: 100%;
  background-color: $light-grey;
}

.loader {
  display: flex;
  position: fixed;
  z-index: $z-index-overlay;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $procure-brand-accent;

  &--public {
    z-index: $z-index-navbar;
  }
}

.loader__img {
  display: block;
  width: 220px;
  max-width: 100%;
  height: auto;
}

.loader__message {
  padding: 1rem;
  color: $white;
  font-size: 1rem;
}

// stylelint-disable declaration-no-important

[hidden] {
  display: none !important;
}

// stylelint-enable declaration-no-important
