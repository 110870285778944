@import "../../base/variables";
@import "../../base/mixins";

$entity-maintenance-mode-spacing: $base-space !default;
$entity-maintenance-mode-padding: $base-space !default;
$entity-maintenance-mode-title-spacing: $base-space !default;
$entity-maintenance-mode-text-spacing: $base-space !default;

.entity-maintenance-mode {
  @include card;

  max-width: 30rem;
  margin: $entity-maintenance-mode-spacing auto;
  padding: $entity-maintenance-mode-padding;

  &__title {
    margin: 0 0 $entity-maintenance-mode-title-spacing;
  }

  &__text {
    margin: 0 0 $entity-maintenance-mode-text-spacing;
  }
}
