@import "../../base/variables";
@import "../../base/mixins";

$label-value-list-label-color: $procure-grey !default;
$label-value-list-label-font-weight: $procure-label-font-weight !default;
$label-value-list-border: $light-grey !default;
$label-value-list-label-invalid-color: $procure-brand-error !default;
$label-value-list-item-vertical-padding: $half-space !default;
$label-value-list-item-horizontal-padding: $half-space !default;

.label-value-list {
  $root: &;

  display: flex;
  flex-flow: row wrap;

  &--in-expansion-panel {
    margin: (-$half-space) (-$base-space) 0;
  }

  &__label {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 33.333%;
    padding:
      $label-value-list-item-vertical-padding
      $half-space * 0.5
      $label-value-list-item-vertical-padding
      $label-value-list-item-horizontal-padding;
    color: $label-value-list-label-color;
    font-size: 0.75rem;
    font-weight: $label-value-list-label-font-weight;
    letter-spacing: 0.05em;

    &:not(:nth-last-child(2)) {
      border-bottom: 1px solid $label-value-list-border;

      @include up-to(m) {
        border-bottom: 0;
      }
    }

    &--invalid {
      color: $label-value-list-label-invalid-color;
    }

    mat-label {
      display: inline-flex;
      align-items: center;
      gap: $quarter-space;
    }

    .input-required {
      font-size: 1.5rem;
      line-height: 0.85;
    }

    #{$root}--in-expansion-panel & {
      padding-left: $label-value-list-item-horizontal-padding * 2;

      @include up-to(m) {
        padding-right: $label-value-list-item-horizontal-padding * 2;
      }
    }

    @include up-to(m) {
      width: 100%;
      padding: $label-value-list-item-vertical-padding $label-value-list-item-horizontal-padding 0;
    }
  }

  &__value {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 66.666%;
    padding:
      $label-value-list-item-vertical-padding
      $label-value-list-item-horizontal-padding
      $label-value-list-item-vertical-padding
      $half-space * 0.5;

    /* stylelint-disable-next-line selector-max-universal */
    > * {
      width: 100%;
    }

    .show-mode__label,
    .mat-mdc-form-field-label-wrapper {
      display: none;
    }

    .mat-mdc-form-field {
      margin-bottom: 0;
    }

    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding-top: 0;
    }

    .show-mode,
    .show-field {
      margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
    }

    &:not(:last-child) {
      border-bottom: 1px solid $label-value-list-border;
    }

    #{$root}--in-expansion-panel & {
      padding-right: $label-value-list-item-horizontal-padding * 2;

      @include up-to(m) {
        padding-left: $label-value-list-item-horizontal-padding * 2;
      }
    }

    @include up-to(m) {
      width: 100%;
      padding: 0 $label-value-list-item-horizontal-padding $label-value-list-item-vertical-padding;
    }
  }
}
