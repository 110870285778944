@import "../../base/variables";
@import "../../base/mixins";

$expansion-panel-margin: $base-space !default;
$expansion-panel-content-padding: $half-space !default;
$expansion-panel-disabled-header-color: rgba($black, 0.87) !default;

.expansion-panel {
  @include card;

  position: relative;
  margin-bottom: $expansion-panel-margin;
  overflow: hidden;

  .mat-expansion-panel-header-title {
    align-items: center;
    gap: $half-space;
    font-weight: 700;
  }

  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding-top: $expansion-panel-content-padding;
  }

  .mat-expansion-indicator {
    width: 0.85rem;

    &::after {
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-title {
    color: $expansion-panel-disabled-header-color;
  }

  &__color {
    width: 1.5em;
    height: 1.5em;
    border-radius: $border-radius;
    background-color: $procure-grey;
  }
}
