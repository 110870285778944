.procure-api-error-status {
  display: block;
  margin: 0.5rem 0 0;
  font-size: 0.6rem;
}

.procure-api-error-date {
  display: block;
  margin: 0.5rem 0 0;
  font-size: 0.6rem;
}
