@import "../../base/variables";
@import "../../base/mixins";

$form-header-z-index: $z-index-navigation-fixed !default;
$form-header-vertical-padding: $base-space !default;
$form-header-horizontal-padding: $base-space !default;
$form-header-background-color: $white !default;
$form-header-shadow: $box-shadow !default;
$form-header-button-spacing: $half-space !default;
$form-header-top-margin: $base-space !default;

.form-header {
  $root: &;

  @include card;
  @include container;

  position: sticky;
  z-index: $form-header-z-index;
  top: 0;
  box-sizing: border-box;
  flex-direction: column;
  min-height: 2.375rem;
  margin-top: $form-header-top-margin;
  padding: 0;
  transition: width 200ms ease-in-out, box-shadow 200ms ease-in-out;
  background-color: $form-header-background-color;
  box-shadow: $form-header-shadow;

  &--sticky {
    width: 100%;
    box-shadow: 0 1px 5px rgba(#000000, 0.25);

    #{$root}__container {
      padding: $form-header-vertical-padding 0;
    }
  }

  &__container {
    @include container;

    box-sizing: border-box;
    flex-direction: column;
    padding: $form-header-vertical-padding $form-header-horizontal-padding;
    transition: padding 200ms ease-in-out;
  }

  &__button-positioner {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .procure-button ~ .procure-button {
    margin-left: $form-header-button-spacing;
  }

  &__left,
  &__center,
  &__right {
    > div {
      display: flex;
    }
  }
}

.spacer {
  position: relative;
}
