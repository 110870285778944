@import "../../base/variables";
@import "../../base/mixins";

$entity-header-spacing: $base-space !default;
$entity-header-padding: $base-space !default;
$entity-header-revision-color: $procure-grey !default;
$entity-header-meta-padding: $base-space !default;
$entity-header-header-padding: $base-space !default;
$entity-header-item-spacing: $half-space !default;

.entity-header {
  $root: &;

  @include container;
  @include card;

  box-sizing: border-box;
  flex-direction: column;
  margin-top: $entity-header-spacing;
  margin-bottom: $entity-header-spacing;
  padding: $entity-header-padding;

  &__meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $entity-header-item-spacing;
    width: 100%;
    min-height: 1.3625rem;

    &:last-child {
      padding-bottom: 0;
    }

    @include up-to(m) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__badge {
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $entity-header-item-spacing;
    margin-top: $entity-header-meta-padding;

    @include up-to(m) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__main-content {
    display: block;
    width: 100%;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__status {
    #{$root} & .badge {
      font-size: 0.9rem;
      letter-spacing: 0.05em;
    }
  }

  &__revision {
    color: $entity-header-revision-color;
  }
}
