@import "../../base/variables";

$breadcrumbs-separator-spacing: $half-space !default;
$breadcrumbs-separator-color: $procure-grey !default;
$breadcrumbs-closed-line-color: $black !default;
$breadcrumbs-link-color: $procure-brand-accent !default;

.breadcrumbs {
  $root: &;

  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: inline;

    &::after {
      content: "/";
      margin: 0 $breadcrumbs-separator-spacing;
      color: $breadcrumbs-separator-color;
    }

    &:last-of-type::after {
      content: none;
    }

    &--closed #{$root}__label {
      text-decoration: line-through;
      text-decoration-color: rgba($breadcrumbs-closed-line-color, 0.5);
      text-decoration-thickness: 2px;
    }
  }

  &__link {
    color: $breadcrumbs-link-color;
    text-decoration: none;
  }
}
