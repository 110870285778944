@import "../../base/variables";
@import "../../base/mixins";

.full-width-layout {
  @include container;

  &__main-content {
    width: 100%;
  }
}
