//  ==========================================
//  Colors
//  ==========================================
$light-grey: #eaeaea;
$medium-grey: #adadad;
$grey: #4d4d4d;
$off-white: #f2f2f2;
$black: #000000;
$soq-red: #eb0023;

//  ==========================================
//  Responsive Sizes
//  ==========================================
$screen-sm-min: 48rem;
$screen-md-min: 62rem;
$screen-lg-min: 75rem;
$screen-xl-min: 87rem;
$screen-xxl-min: 98rem;
$screen-xxxl-min: 120rem;

$breakpoints: (
  s: $screen-sm-min,
  m: $screen-md-min,
  l: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min,
  xxxl: $screen-xxxl-min,
);

//  ==========================================
//  Fonts
//  ==========================================
$font-family-sans-serif: "Open Sans", "Arial", sans-serif !default;
$font-family-serif: "Georgia", "Times New Roman", "Times", serif !default;
$font-family-monospace: "Menlo", "Monaco", "Consolas", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$primary-font: $font-family-sans-serif !default;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 700;

//  ==========================================
//  Base Layout
//  ==========================================
$header-height: 5.625rem;
$component-max-width: 79.375rem;

//  ==========================================
//  Miscellaneous
//  ==========================================
$box-shadow: 0 6px 12px -2px rgba($black, 0.15);

//  ==========================================
//  Z-Index
//  ==========================================
$z-index: (
  menu: 10,
);

@import "../../app/libs/core/styles/base/variables";

$form-error-color: $procure-brand-error;
$quarter-space: $base-space * 0.25;
