@import "../../base/variables";

$button-icon-spacing: $half-space * 0.5 !default;

.procure-button {
  // Set the min-height so that icon and non-icon buttons can be placed next to each other.
  min-height: 2.7em;

  .procure-button-wrapper {
    width: 100%;
  }

  .procure-button-wrapper--has-prefix,
  .procure-button-wrapper--has-suffix {
    display: flex;
    align-items: center;
  }

  .procure-button-wrapper--has-prefix {
    .mat-icon:first-child {
      margin-right: $button-icon-spacing * 0.5;
    }
  }

  .procure-button-wrapper--has-suffix {
    justify-content: space-between;

    .mat-icon:last-child {
      margin-left: $button-icon-spacing * 0.5;
    }
  }

  &.procure-icon-button .procure-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.procure-anchor {
  min-width: 0;

  &.procure-icon-button {
    justify-content: center;
  }
}
