// Override variables
@import "./base/variables";
@import "./base/mixins";
@import "./base/typography";
@import "./base/icons";

//  Import common styles
@import "../app/libs/core/styles/main";

// Base Layout
@import "layout/app-main";

//  Components
@import "component/expansion-pannel";
@import "component/forms";
@import "component/tables";
@import "component/input";
@import "component/form-attachments";
@import "component/links";
@import "component/menus";
@import "component/ng-select";
@import "component/ckeditor";
@import "component/stepper";
@import "component/dialog";
@import "component/tooltips";
@import "component/checkbox";
@import "component/spinner";
@import "component/text";
@import "component/overrides";
@import "component/dialogs";

body {
  position: relative;
  font-family: $primary-font;
  font-size: 1rem;
  line-height: 1.5;
}
