@use "sass:color";
@import "../../base/variables";

$dialog-info-spacing: $half-space !default;
$dialog-info-padding: $half-space !default;
$dialog-info-border: color.adjust($procure-brand-warning, $lightness: -10%) !default;
$dialog-info-background: rgba($procure-brand-warning, 0.4) !default;
$dialog-info-color: color.adjust($procure-brand-warning, $lightness: -25%) !default;
$dialog-info-icon-spacing: $half-space !default;
$dialog-content-spacing: $half-space !default;

.dialog {
  &__body {
    overflow: hidden;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: $dialog-info-spacing;
    padding: $dialog-info-padding;
    border: 1px solid $dialog-info-border;
    background-color: $dialog-info-background;
    color: $dialog-info-color;
  }

  &__info-icon {
    margin-right: $dialog-info-icon-spacing;
  }

  .mat-mdc-dialog-content {
    margin-bottom: $dialog-content-spacing;

    &:last-child {
      margin-bottom: $dialog-content-spacing * -1;
    }
  }

  &--info,
  &--prompt,
  &--error {
    .mat-mdc-dialog-container .mdc-dialog__content.mat-mdc-dialog-content {
      padding-bottom: $half-space;
    }
  }
}
