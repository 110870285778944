input,
.mat-mdc-form-field {
  font-family: $font-family-sans-serif;
}

.mat-mdc-form-field {
  width: 100%;
  margin-bottom: $half-space;

  &.ng-invalid.ng-touched {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      /* stylelint-disable-next-line declaration-no-important */
      border-color: $procure-brand-error !important;
    }

    /* stylelint-disable-next-line selector-max-specificity */
    .mdc-notched-outline__notch .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
      color: $procure-brand-error;
    }
  }
}

.input-required {
  display: inline-block;
  height: 0.9rem;
  transform: translateY(40%);
  color: $procure-brand-error;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0;
}

.mat-mdc-form-field-required-marker {
  display: none;
}

.form-row-link {
  margin-top: $half-space * -1;
  margin-bottom: $half-space;
  font-size: 0.75rem;
  line-height: 2;
}

.mdc-text-field--focused {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    /* stylelint-disable-next-line declaration-no-important */
    border-width: 1px !important;
  }
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #d9d9d9 !important;
}

.mdc-notched-outline__notch {
  /* stylelint-disable-next-line declaration-no-important */
  width: fit-content !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-top: 1px solid #d9d9d9 !important;

  .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
    margin-top: -2px;
    padding: 0 $quarter-space;
    background-color: $white;
    color: $procure-grey;
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
}

.mat-mdc-radio-button {
  .mdc-form-field {
    display: flex;
    width: 20px;

    .mdc-radio {
      padding: 0;

      .mdc-radio__native-control {
        top: 0;
        right: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.mat-mdc-form-field-infix {
  padding-right: 5px;
  padding-left: 5px;
}

.mdc-text-field--outlined .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 1rem;
}

.mat-form-field-disabled {
  .mdc-notched-outline__leading {
    border-style: dashed none dashed dashed;
  }

  .mdc-notched-outline__notch {
    /* stylelint-disable-next-line declaration-no-important */
    border-top-style: dashed !important;
    border-bottom-style: dashed;
  }

  .mdc-notched-outline__trailing {
    border-style: dashed dashed dashed none;
  }
}

.ng-select .ng-select-container .ng-value {
  font-size: 1rem;
}

.ng-select .ng-arrow-wrapper {
  line-height: 1.3;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input.mat-mdc-input-element::placeholder {
  color: $grey;
}
