@import "../../base/variables";
@import "../../base/mixins";

$form-header-uploads-background-color: rgba($black, 0.5) !default;
$form-header-uploads-progress-bar-spacing: $half-space !default;
$form-header-uploads-border-color: $light-grey !default;
$form-header-uploads-content-spacing: $half-space !default;
$form-header-uploads-header-padding: $half-space $base-space !default;

.form-header-uploads-wrapper {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $form-header-uploads-background-color;
}

.form-header-uploads {
  @include container;

  // stylelint-disable-next-line declaration-no-important
  width: fit-content !important;

  &__content {
    @include card;

    margin-top: $form-header-uploads-content-spacing;
  }

  &__header {
    min-width: 25rem;
    padding: $form-header-uploads-header-padding;
    border-bottom: 1px solid $form-header-uploads-border-color;

    h2 {
      margin: 0.25rem 0;
    }
  }

  &__uploads {
    max-height: 25rem;
    overflow: auto;
  }

  &__file-upload {
    min-width: 25rem;
    padding: $form-header-uploads-header-padding;
    border-bottom: 1px solid $form-header-uploads-border-color;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__progress {
    margin-top: $form-header-uploads-progress-bar-spacing;
  }
}
