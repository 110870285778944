@import "../base/variables";

// stylelint-disable declaration-no-important

$tooltip-background: rgba($black, 90%);

.mdc-tooltip .mdc-tooltip__surface {
  max-width: 30em !important;
  padding: 0.25rem $half-space !important;
  background-color: $tooltip-background !important;
  font-size: 0.85rem !important;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.35 !important;
}

// stylelint-enable declaration-no-important
