.prevent-line-breaks {
  white-space: nowrap;
}

h1,
h2 {
  font-weight: 400;
}

.text-link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
