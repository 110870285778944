@import "../../base/variables";

$number-badge-background-color: $procure-grey !default;
$number-badge-color: $white !default;

.number-badge {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background-color: $number-badge-background-color;
  color: $number-badge-color;
  font-size: 0.75em;
  font-weight: 700;
  cursor: default;

  &--clickable {
    cursor: pointer;
  }
}
