@import "../base/variables";

.mat-mdc-checkbox {
  &.ng-touched.ng-invalid {
    .mdc-checkbox__background {
      /* stylelint-disable-next-line declaration-no-important */
      border-color: $form-error-color !important;
    }

    .mdc-label {
      color: $form-error-color;
    }
  }

  .mdc-checkbox {
    padding: 0;

    .mdc-checkbox__background {
      top: 0;
      left: 0;
    }

    .mdc-checkbox__native-control {
      pointer-events: none;
    }
  }

  .mdc-label {
    padding-left: $half-space;
  }
}
