@import "../../base/variables";
@import "../../base/mixins";

$view-not-implemented-spacing: $base-space !default;
$view-not-implemented-padding: $base-space !default;
$view-not-implemented-title-spacing: $base-space !default;
$view-not-implemented-text-spacing: $base-space !default;

.view-not-implemented {
  @include card;

  max-width: 30rem;
  margin: $view-not-implemented-spacing auto;
  padding: $view-not-implemented-padding;

  &__title {
    margin: 0 0 $view-not-implemented-title-spacing;
  }

  &__text {
    margin: 0 0 $view-not-implemented-text-spacing;
  }
}
