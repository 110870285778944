/* open-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-300.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-300italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-500.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-500italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-500italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-600.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-700.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-800.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800.svg#OpenSans") format("svg"); /* Legacy iOS */
}

/* open-sans-800italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.eot"); /* IE9 Compat Modes */
  src:
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/open-sans-v34-latin_latin-ext-800italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
