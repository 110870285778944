@import "../../base/variables";
@import "../../base/mixins";

$entity-deleted-spacing: $base-space !default;
$entity-deleted-padding: $base-space !default;
$entity-deleted-title-spacing: $base-space !default;
$entity-deleted-text-spacing: $base-space !default;

.entity-deleted {
  @include card;

  max-width: 30rem;
  margin: $entity-deleted-spacing auto;
  padding: $entity-deleted-padding;

  &__title {
    margin: 0 0 $entity-deleted-title-spacing;
  }

  &__text {
    margin: 0 0 $entity-deleted-text-spacing;
  }
}
