@import "../../base/variables";

$badge-border-radius: $border-radius !default;
$badge-color: $white !default;

.badge {
  display: inline-block;
  box-sizing: border-box;
  height: 1.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #555555;
  border-radius: $badge-border-radius;
  color: #555555;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.15;
  text-decoration: none;
  white-space: nowrap;
}
