$table-column-widths: (
  "fromState": 8rem,
  "toState": 8rem,
  "responsible": 10rem,
  "date": 10rem,
);

$table-column-relative-widths: (
  "fromState": 25%,
  "toState": 25%,
  "responsible": 25%,
  "date": 25%,
);

.entity-log {
  &__table-wrapper {
    overflow: auto;
  }

  &__table {
    width: 100%;
  }

  &__column {
    @each $column, $width in $table-column-widths {
      &--#{$column} {
        min-width: $width;
      }
    }

    @each $column, $width in $table-column-relative-widths {
      &--#{$column} {
        width: $width;
      }
    }
  }
}
