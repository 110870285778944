//  Remove up-down arrows from number input in Firefox
@-moz-document url-prefix("") {
  //  stylelint-disable selector-no-qualifying-type
  input {
    &[type="number"] {
      //  stylelint-disable-next-line property-no-vendor-prefix
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        //  stylelint-disable-next-line property-no-vendor-prefix
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  //  stylelint-enable selector-no-qualifying-type
}
