@import "../../base/variables";
@import "../../base/mixins";

$entity-not-found-spacing: $base-space !default;
$entity-not-found-padding: $base-space !default;
$entity-not-found-title-spacing: $base-space !default;
$entity-not-found-text-spacing: $base-space !default;
$entity-not-found-button-spacing: $half-space !default;

.entity-not-found {
  @include card;

  max-width: 30rem;
  margin: $entity-not-found-spacing auto;
  padding: $entity-not-found-padding;

  &__title {
    margin: 0 0 $entity-not-found-title-spacing;
  }

  &__text {
    margin: 0 0 $entity-not-found-text-spacing;
  }

  &__button + &__button {
    margin-left: $entity-not-found-button-spacing;
  }
}
