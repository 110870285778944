@import "../../base/variables";
@import "../../base/mixins";

$entity-not-visible-spacing: $base-space !default;
$entity-not-visible-padding: $base-space !default;
$entity-not-visible-title-spacing: $base-space !default;
$entity-not-visible-text-spacing: $base-space !default;

.entity-not-visible {
  @include card;

  max-width: 30rem;
  margin: $entity-not-visible-spacing auto;
  padding: $entity-not-visible-padding;

  &__title {
    margin: 0 0 $entity-not-visible-title-spacing;
  }

  &__text {
    margin: 0 0 $entity-not-visible-text-spacing;
  }
}
