@import "../base/variables";
@import "../base/mixins";

$form-row-gap: $half-space !default;
$form-row-border-color: $light-grey !default;

.form-row {
  $root: &;

  display: flex;
  gap: $form-row-gap;
  box-sizing: border-box;
  justify-content: space-between;

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    flex-grow: 1;
    width: 0;
  }

  &:not(:last-child),
  #{$root}--standalone {
    margin-right: $base-space * -1;
    margin-bottom: $half-space;
    margin-left: $base-space * -1;
    padding-right: $base-space;
    padding-left: $base-space;
    border-bottom: 1px solid $form-row-border-color;
  }

  > :first-child:not(:last-child) {
    @include up-to(m) {
      margin: 0 ($base-space * -1);
      padding: 0 $base-space;
      border-bottom: 1px solid $form-row-border-color;
    }
  }

  @include up-to(m) {
    flex-direction: column;

    /* stylelint-disable-next-line selector-max-universal */
    > * {
      width: 100%;
    }
  }
}
