@import "../base/variables";

h3 {
  margin: 0 0 $half-space;
}

p {
  margin: 0 0 $half-space;
}

ul {
  margin: 0 0 $half-space;

  /* stylelint-disable-next-line selector-max-type */
  li + li {
    margin-top: $half-space * 0.5;
  }
}
