@import "../../base/variables";

$snackbar-info-background: $procure-brand-info !default;
$snackbar-error-background: $procure-brand-error !default;
$snackbar-success-background: $procure-brand-success !default;
$snackbar-icon-color: $white !default;
$snackbar-content-spacing: $half-space !default;
$snackbar-title-color: $white !default;

.snackbar {
  display: flex;
  align-items: center;
  min-height: 3rem;
  margin: -14px -16px;

  &__icon-container {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 3rem;
    border-radius: 4px 0 0 4px;

    &--info {
      background-color: $snackbar-info-background;
    }

    &--error {
      background-color: $snackbar-error-background;
    }

    &--check_circle {
      background-color: $snackbar-success-background;
    }
  }

  &__icon {
    color: $snackbar-icon-color;
  }

  &__content {
    flex-grow: 1;
    padding: $snackbar-content-spacing;
    font-size: 1rem;
  }

  &__title {
    margin-bottom: 0.25rem;
    color: $snackbar-title-color;
    font-weight: 700;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
