.status-badge {
  display: flex;

  &__badge .badge {
    border-radius: 0 $border-radius $border-radius 0;
  }

  &__indicator {
    display: block;
    box-sizing: border-box;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid;
    border-right: 0;
    border-radius: $border-radius 0 0 $border-radius;
  }
}
