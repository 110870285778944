.ck-editor__editable {
  min-height: 250px;
  max-height: 500px;
}

.ck-content {
  line-height: 1.25;

  //  stylelint-disable selector-max-type
  table td,
  table th {
    border: 1px solid $light-grey;
  }
  //  stylelint-enable selector-max-type
}

// NOTE: $dark-disabled-text declaration is missing in Angular Material 17.
// TODO: Alternative to be found if style discrepancies are discovered after migration to new library version.
// .ck-read-only {
//   color: $dark-disabled-text;
// }

.ck-content--responsive {
  word-break: break-word;
  hyphens: auto;

  img {
    max-width: 100%;
    height: auto;
  }
}
