@import "../base/variables";

@mixin table-columns-widths($column-widths, $column-relative-widths) {
  @each $column, $width in $column-widths {
    &--#{$column} {
      min-width: $width;
    }
  }

  @each $column, $width in $column-relative-widths {
    &--#{$column} {
      width: $width;
    }
  }
}

// stylelint-disable selector-no-qualifying-type, no-duplicate-selectors

.mat-mdc-table {
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    padding: $half-space * 0.5;

    &:first-child,
    &:first-child,
    &:first-child {
      padding-left: 24px;
    }

    &:last-child,
    &:last-child,
    &:last-child {
      padding-right: 24px;
    }
  }

  th.mat-mdc-header-cell {
    color: $procure-grey;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.05em;

    .mat-sort-header-container {
      letter-spacing: 0.05em;
    }
  }

  .mat-sort-header-button {
    text-align: left;
  }

  .mat-mdc-header-cell {
    white-space: nowrap;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  .mdc-data-table__content .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: 1px solid rgba(0 0 0 / 12%);
  }
}

.table-button-column {
  width: 1px;
}

// stylelint-enable selector-no-qualifying-type, no-duplicate-selectors
