@import "../../base/variables";

$environment-banner-background-color: $white !default;
$environment-banner-font: $font-family-monospace !default;
$environment-banner-default-color: $procure-brand-warning !default;
$environment-banner-development-color: rgba($procure-status-progress, 0.75) !default;
$environment-banner-staging-color: rgba($procure-status-done, 0.75) !default;
$environment-banner-beta-color: rgba($procure-grey, 0.75) !default;
$environment-banner-text-padding: $base-space !default;

.environment-banner {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: $environment-banner-background-color;
  font-size: 1rem;
  line-height: 1;

  &__container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $environment-banner-default-color;
    font-family: $environment-banner-font;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 40px;
      background-image:
        repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 10px,
          $environment-banner-background-color 10px,
          $environment-banner-background-color 15px
        );
    }

    &--development {
      background-color: $environment-banner-development-color;
    }

    &--staging {
      background-color: $environment-banner-staging-color;
    }

    &--beta {
      background-color: $environment-banner-beta-color;
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 0.25rem $environment-banner-text-padding;
    background-color: $environment-banner-background-color;
    text-align: center;
  }
}
