@use "sass:math";
@import "../../base/variables";
@import "../../base/mixins";

$sidebar-layout-spacing: $half-space !default;

.sidebar-layout {
  @include container;

  flex-wrap: wrap;
  justify-content: space-between;

  &__main-content {
    width: 100%;
  }

  &__sidebar-content {
    width: 100%;
  }

  // stylelint-disable-next-line order/order
  @media (min-width: $screen-md-min) {
    flex-wrap: nowrap;

    &__main-content {
      flex-shrink: 0;
      width: calc(70% - #{$sidebar-layout-spacing});
      margin-right: $sidebar-layout-spacing;
    }

    &__sidebar-content {
      flex-shrink: 0;
      width: calc(30% - #{$sidebar-layout-spacing});
      margin-left: $sidebar-layout-spacing;
    }
  }
}
