@import "../../base/variables";

$loading-indicator-background-color: rgba($black, 0.32) !default;
$loading-indicator-color: $white !default;

.loading-indicator {
  display: flex;
  position: absolute;
  z-index: 975;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  animation: loading-indicator 0.2s ease-in-out 0.1s;
  animation-fill-mode: both;
  opacity: 0;
  background-color: $loading-indicator-background-color;

  &--top {
    align-items: flex-start;
    padding-top: 10%;
  }

  &--long {
    animation-delay: 0.8s;
  }

  &__spinner circle {
    stroke: $loading-indicator-color;
  }
}

@keyframes loading-indicator {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
