@import "../base/mixins";

.cdk-overlay-pane {
  max-width: calc(100% - 48px);
}

.dialog__header {
  padding: $half-space $base-space;
  border-bottom: 1px solid $light-grey;
}

.mat-mdc-dialog-container.mdc-dialog {
  width: 100%;
  max-width: 100%;
}

.mdc-dialog .mat-mdc-dialog-content.mdc-dialog__content {
  margin-bottom: 0;
  padding: $half-space $base-space 0;
  color: $black;
}

.dialog__title {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  // stylelint-disable-next-line declaration-no-important
  margin-top: 0 !important;
  margin-bottom: 0;
  overflow: hidden;
}

.dialog__footer {
  display: flex;
  justify-content: flex-end;
  gap: $half-space;
  padding: $half-space $base-space;
  border-top: 1px solid $light-grey;

  .button {
    margin-bottom: 0;
  }

  @include up-to(l) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  margin: 0;
}
