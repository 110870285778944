@use "sass:math";
@import "./variables";

@mixin container() {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-right: $base-space;
  padding-left: $base-space;

  @media (min-width: $screen-sm-min) {
    width: 750px;
  }

  @media (min-width: $screen-md-min) {
    width: 970px;
  }

  @media (min-width: $screen-lg-min) {
    width: 1270px;
  }
}

@mixin all-direct-descendants {
  > :not([inexisting-attr]) {
    @content;
  }
}

@mixin card {
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $box-shadow;
}

@mixin hintable-row {
  .hintable {
    display: flex;
    flex-flow: nowrap;
    align-items: center;

    &__hint {
      flex-grow: 0;
      width: 24px;
      height: 24px;
      margin-bottom: $half-space;
      margin-left: math.div($half-space, 2);
      color: $procure-grey;
      cursor: help;
    }

    &__control {
      width: 100%;
    }
  }
}

@function breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @return map-get($breakpoints, $breakpoint);
  } @else {
    @warn "No breakpoint called "#{$breakpoint}" was found. Possible values include: #{map-keys($breakpoints)}";
    @return null;
  }
}

// The at-least mixin acts as a min-width media query for the given breakpoint.
@mixin at-least($breakpoint) {
  $width: breakpoint($breakpoint);

  @if $width {
    @media (min-width: $width) {
      @content;
    }
  }
}

@mixin up-to($breakpoint) {
  $width: breakpoint($breakpoint) * 0.999;

  @if $width {
    @media (max-width: $width) {
      @content;
    }
  }
}
