@use "@angular/material" as mat;
@include mat.core;

//  This file is for setting global styles
@import "base/variables";
@import "base/mixins";

// Normalize styles across browsers
@import "~normalize.css/normalize.css";
@import "layout/app-main";

//  Include theme styles for core and each component used in your app.
//  Alternatively, you can import and @include the theme mixins for each component
//  that you are using.
// @include angular-material-theme($theme);
@include mat.all-component-themes($theme);

//  Override typography for all Angular Material, including mat-base-typography and all components.
// @include angular-material-typography($custom-typography);
@include mat.all-component-typographies($custom-typography);

// Components
@import "components/badge/badge";
@import "components/breadcrumbs/breadcrumbs";
@import "components/button/button";
@import "components/dialog/dialog";
@import "components/entity-badge/entity-badge";
@import "components/entity-deleted/entity-deleted";
@import "components/entity-header/entity-header";
@import "components/entity-log/entity-log";
@import "components/entity-not-found/entity-not-found";
@import "components/entity-not-visible/entity-not-visible";
@import "components/entity-maintenance-mode/entity-maintenance-mode";
@import "components/environment-banner/environment-banner";
@import "components/expansion-panel/expansion-panel";
@import "components/file-icon/file-icon";
@import "components/form-header/form-header";
@import "components/form-header-uploads/form-header-uploads";
@import "components/full-width-layout/full-width-layout";
@import "components/label-value-list/label-value-list";
@import "components/loading-indicator/loading-indicator";
@import "components/number-badge/number-badge";
@import "components/paginator/paginator";
@import "components/show-field/show-field";
@import "components/sidebar-layout/sidebar-layout";
@import "components/snackbar/snackbar";
@import "components/status-badge/status-badge";
@import "components/view-not-implemented/view-not-implemented";

//  Other
@import "other/backdrop";
@import "other/dialogs";
@import "other/errors";
@import "other/forms";
@import "other/text";

body {
  position: relative;
  font-family: $primary-font;
}
