@import "../../base/variables";

$show-field-margin: $half-space !default;
$show-field-label-color: $procure-grey !default;
$show-field-label-font-weight: $procure-label-font-weight !default;
$show-field-text-spacing: $half-space !default;

.show-field {
  margin-bottom: $show-field-margin;

  &__label {
    color: $show-field-label-color;
    font-size: 0.75rem;
    font-weight: $show-field-label-font-weight;
    letter-spacing: 0.05em;
  }

  &__value {
    word-break: break-word;

    p {
      margin: 0;

      // stylelint-disable-next-line selector-max-type
      + p {
        margin-top: $show-field-text-spacing;
      }
    }
  }
}
