.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $procure-brand-accent;
}

// stylelint-disable-next-line selector-max-specificity
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: white;
}

.mat-expansion-panel-content {
  border-top: 1px solid $light-grey;
  font-size: inherit;
  line-height: inherit;

  .mat-expansion-panel-body {
    padding-top: $base-space;
    padding-bottom: 0;
  }
}
