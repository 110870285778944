$mat-paginator-padding: $half-space $base-space !default;

$mat-paginator-items-per-page-label-margin: 0 $half-space !default;
$mat-paginator-items-per-page-label-color: $procure-grey !default;
$mat-paginator-selector-margin: 0 !default;
$mat-paginator-selector-trigger-width: 96px !default;
$mat-paginator-selector-trigger-outline-width: 64px !default;
$mat-paginator-selector-trigger-fill-width: 64px !default;

/** @deprecated Use `$mdc-paginator-selector-trigger-width` instead. @breaking-change 8.0.0 */
$mat-paginator-selector-trigger-min-width: $mat-paginator-selector-trigger-width !default;

/** @deprecated Unused. To be removed */
$mat-paginator-range-actions-min-height: 48px !default;

$mat-paginator-range-label-margin: 0 24px 0 24px !default;
$mat-paginator-range-label-color: $procure-grey !default;

$mat-paginator-button-margin: 8px !default;
$mat-paginator-button-icon-height: 8px !default;
$mat-paginator-button-icon-width: 8px !default;

$mat-paginator-button-increment-icon-margin: 12px !default;
$mat-paginator-button-decrement-icon-margin: 16px !default;

$mat-paginator-button-first-last-icon-width: 14px !default;

$mat-paginator-button-first-icon-margin: 3px !default;
$mat-paginator-button-last-icon-margin: 15px !default;

$mat-paginator-button-first-decrement-icon-margin: 21px !default;
$mat-paginator-button-last-increment-icon-margin: 9px !default;

.mat-mdc-paginator {
  display: block;
}

// Note: this wrapper element is only used to get the flexbox vertical centering to work
// with the `min-height` on IE11. It can be removed if we drop support for IE.
.mat-paginator-outer-container {
  display: flex;
}

.mat-paginator-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: $mat-paginator-padding;
}

.mat-paginator-page-size {
  display: flex;
  align-items: baseline;
}

.mat-paginator-page-size-label {
  margin: $mat-paginator-items-per-page-label-margin;
  color: $mat-paginator-items-per-page-label-color;
  white-space: nowrap;
}

.mat-paginator-page-size-select.mat-mdc-form-field {
  // stylelint-disable-next-line declaration-no-important
  width: $mat-paginator-selector-trigger-width !important;
  margin: $mat-paginator-selector-margin;

  &.mat-mdc-form-field-appearance-outline {
    width: $mat-paginator-selector-trigger-outline-width;
  }

  &.mat-mdc-form-field-appearance-fill {
    width: $mat-paginator-selector-trigger-fill-width;
  }
}

.mat-paginator-range-label {
  margin: $mat-paginator-range-label-margin;
  color: $mat-paginator-range-label-color;
}

.mat-paginator-range-actions {
  display: flex;
  align-items: center;
}

.mat-paginator-icon {
  fill: currentColor;
}
