@import "../base/variables";

.mat-mdc-progress-spinner {
  circle {
    stroke: $white;
  }

  &.spinner--dark circle {
    stroke: $black;
  }
}
